import PropTypes from 'prop-types';
import { memo, useMemo, useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'store';
import { Box, Drawer, MenuItem, Stack, Slide, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import ArrowRight from 'assets/images/arrow-right.png';

const MenuListItem = ({ open, delay, title, url, hasArrow }) => {
    const theme = useTheme();
    const [slideUp, setSlideUp] = useState(false);
    const { drawerOpen } = useSelector((state) => state.menu);
    useEffect(() => {
        console.log(drawerOpen);
        setTimeout(() => {
            setSlideUp(!!drawerOpen);
        }, delay);
    }, [drawerOpen, delay]);

    return (
        <MenuItem
            sx={{
                paddingLeft: '90px',
                paddingRight: '20px',
                paddingTop: '20px',
                paddingBottom: '20px',
                cursor: 'pointer',
                [theme.breakpoints.down(600)]: {
                    paddingLeft: '25px'
                }
            }}
            onClick={() => {
                window.location.href = url;
            }}
        >
            <Slide direction="up" in={slideUp} appear timeout={{ enter: 1000, exit: 800 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            color: hasArrow ? '#E15C12' : '#1C1C1C',
                            fontSize: '3.125rem',
                            fontWeight: 800,
                            marginTop: 0,
                            fontFamily: 'Heebo, Raleway, san-self',
                            lineHeight: 1.2,
                            letterSpacing: '-0.00833em',
                            [theme.breakpoints.down(960)]: {
                                fontSize: '1.875rem'
                            }
                        }}
                    >
                        {title}
                    </Typography>
                    {hasArrow && <img src={ArrowRight} alt="" style={{ width: 30, height: 30, marginLeft: '20px' }} />}
                </Box>
            </Slide>
        </MenuItem>
    );
};

MenuListItem.propTypes = {
    open: PropTypes.bool,
    delay: PropTypes.number,
    title: PropTypes.string,
    url: PropTypes.string,
    hasArrow: PropTypes.bool
};

export default MenuListItem;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { AppBar, Box, Divider, Grid, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import PropTypes from 'prop-types';

import { format } from 'date-fns';

import hotmobFooterLogo from 'assets/images/footer/logo.png';
import facebook from 'assets/images/footer/facebook.png';
import linkedin from 'assets/images/footer/linkedin.png';

const Footer = ({ name, haveDropDown, dropdownDetail, url }) => {
    const theme = useTheme();

    return (
        <footer style={{ maxWidth: '1280px', margin: '0 auto', padding: '20px 24px', position: 'relative', zIndex: 90 }}>
            <Divider sx={{ margin: '128px 0' }} />
            <Grid container spacing={0} sx={{ width: '100% !important', marginLeft: '0 !important' }}>
                <Grid item xs={12} sx={{ marginBottom: '40px' }}>
                    <Box component="img" alt="hotmob-footer-logo" src={hotmobFooterLogo} sx={{ width: '180px', height: 'auto' }} />
                </Grid>
                <Grid container item xs={12} sx={{ marginBottom: '40px' }}>
                    <Grid item xs={8} />
                    <Grid item xs={4}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: '#FFFFFF',
                                    fontSize: '24px',
                                    marginTop: 0,
                                    fontFamily: 'PlusJakartaSans',
                                    fontWeight: 400,
                                    lineHeight: '30.24px'
                                }}
                            >
                                Follow Us
                            </Typography>
                            <Box component="img" alt="facebook" src={facebook} />
                            <Box component="img" alt="linkedin" src={linkedin} />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sx={{ marginBottom: '40px' }}>
                    <Grid item xs={7}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: '#FFFFFF',
                                fontSize: '20px',
                                marginTop: 0,
                                fontFamily: 'PlusJakartaSans',
                                fontWeight: 400,
                                lineHeight: '25.2px'
                            }}
                        >
                            Copyright © Hotmob Limited {format(new Date(), 'yyyy')}. All Rights Reserved.
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around', minWidth: '450px' }}>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: '#FFFFFF',
                                    fontSize: '1.25rem',
                                    marginTop: 0,
                                    marginLeft: '0.5rem',
                                    fontFamily: 'PlusJakartaSans',
                                    fontWeight: 400,
                                    lineHeight: '25.2px',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    [theme.breakpoints.down(960)]: {
                                        fontSize: '0.75rem'
                                    }
                                }}
                                onClick={() => {
                                    window.open('https://www.hot-mob.com/privacy-policy/');
                                }}
                            >
                                Privacy Policy
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: '#FFFFFF',
                                    fontSize: '1.25rem',
                                    marginTop: 0,
                                    marginLeft: '0.5rem',
                                    fontFamily: 'PlusJakartaSans',
                                    fontWeight: 400,
                                    lineHeight: '25.2px',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    [theme.breakpoints.down(960)]: {
                                        fontSize: '0.75rem'
                                    }
                                }}
                                onClick={() => {
                                    window.open('https://www.hot-mob.com/privacy-policy/');
                                }}
                            >
                                About Us
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: '#FFFFFF',
                                    fontSize: '1.25rem',
                                    marginTop: 0,
                                    marginLeft: '0.5rem',
                                    fontFamily: 'PlusJakartaSans',
                                    fontWeight: 400,
                                    lineHeight: '25.2px',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    [theme.breakpoints.down(960)]: {
                                        fontSize: '0.75rem'
                                    }
                                }}
                                onClick={() => {
                                    window.open('https://www.hot-mob.com/privacy-policy/');
                                }}
                            >
                                Contect Us
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </footer>
    );
};

export default Footer;

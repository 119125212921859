import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import OldLayout from 'layout/OldLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const PostListPage = Loadable(lazy(() => import('views/blog-post-list-page')));
const PostDetailPage = Loadable(lazy(() => import('views/blog-post-detail-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <OldLayout />,
    children: [
        {
            path: '/blog',
            element: <PostListPage />
        },
        {
            path: '/blog/:slug',
            element: <PostListPage />
        },
        {
            path: '/blog/:slug/:page',
            element: <PostListPage />
        },
        {
            path: '/blog/detail/:id',
            element: <PostDetailPage />
        }
    ]
};

export default MainRoutes;

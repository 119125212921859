import { createSlice } from '@reduxjs/toolkit';

import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    error: null,
    categoryList: null,
    postList: null,
    postCount: null
};

const slice = createSlice({
    name: 'postList',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setCategoryList(state, action) {
            state.categoryList = action.payload;
        },
        setPostListInfo(state, action) {
            state.postList = action.payload.posts;
            state.postCount = action.payload.count;
        },
        reset(state, action) {
            state.error = null;
            state.categoryList = null;
            state.postList = null;
            state.postCount = null;
        }
    }
});

export default slice.reducer;

export function getCategories(data, callback) {
    return async () => {
        try {
            const response = await axios.get(`/api/category/list/public`, {});
            dispatch(slice.actions.setCategoryList(response.data.categories));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getPostList(data, callback) {
    return async () => {
        try {
            const response = await axios.get(`/api/post/list/${data.categoryId}/${data.page}`, {});
            console.log(response);
            dispatch(slice.actions.setPostListInfo(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function clearStore(data, callback) {
    return () => {
        dispatch(slice.actions.reset());
        if (callback) {
            callback();
        }
    };
}

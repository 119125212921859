import PropTypes from 'prop-types';
import { memo, useMemo, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, Grid, MenuItem, Stack, Slide, Typography, useMediaQuery } from '@mui/material';
import MenuListItem from './MenuListItem';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { drawerWidth } from 'store/constant';
import Chip from 'ui-component/extended/Chip';

import FacebookIcon from 'assets/images/facebook.svg';
import LinkedinIcon from 'assets/images/linkedin.svg';
import ShowcaseIcon from 'assets/images/showcase.svg';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const [slideUp, setSlideUp] = useState(false);

    const menu = [
        {
            title: 'Home',
            path: 'https://www.hot-mob.com/',
            arrow: false
        },
        {
            title: 'About Us',
            path: 'https://www.hot-mob.com/about/',
            arrow: false
        },
        {
            title: 'Data Services',
            path: 'https://www.hot-mob.com/data/',
            arrow: false
        },
        {
            title: 'Advertise',
            path: 'https://www.hot-mob.com/advertise/',
            arrow: false
        },
        {
            title: 'Monetize',
            path: 'https://www.hot-mob.com/monetize/',
            arrow: false
        },
        {
            title: 'Blog',
            path: 'https://uat2.hot-mob.com/',
            arrow: false
        },
        {
            title: 'Contact Us',
            path: 'https://www.hot-mob.com/contact-us/',
            arrow: true
        }
    ];

    const drawer = useMemo(
        () => (
            <Box
                sx={{
                    height: 'calc(100vh - 104.67px)',
                    minHeight: '700px',
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'center',
                    [theme.breakpoints.down(600)]: {
                        height: 'calc(100vh - 70px)',
                        minHeight: 'unset'
                    }
                }}
            >
                {menu.map((item, index) => (
                    <MenuListItem
                        open={drawerOpen}
                        delay={(menu.length - (index + 1)) * 50}
                        title={item.title}
                        url={item.path}
                        hasArrow={item.arrow}
                    />
                ))}
                <Grid
                    container
                    sx={{
                        paddingLeft: '70px',
                        paddingRight: '20px',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                        cursor: 'pointer',
                        [theme.breakpoints.down(600)]: {
                            paddingLeft: '5px'
                        }
                    }}
                >
                    <Grid item>
                        <a href="https://www.linkedin.com/company/hotmob/" target="_blank" rel="noopener noreferrer">
                            <img src={LinkedinIcon} alt="" style={{ width: 72, height: 72, padding: 12 }} />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://www.facebook.com/HotmobMobile" target="_blank" rel="noopener noreferrer">
                            <img src={FacebookIcon} alt="" style={{ width: 72, height: 72, padding: 12 }} />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://showcase.hot-mob.com" target="_blank" rel="noopener noreferrer">
                            <img src={ShowcaseIcon} alt="" style={{ width: 72, height: 72, padding: 12 }} />
                        </a>
                    </Grid>
                </Grid>
            </Box>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matchUpMd]
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="right"
                open={drawerOpen}
                hideBackdrop
                transitionDuration={{
                    appear: 1000,
                    enter: 500,
                    exit: 1000
                }}
                onClose={() => dispatch(openDrawer(!drawerOpen))}
                sx={{
                    [theme.breakpoints.down('md')]: {
                        top: '70px'
                    },
                    '& .MuiDrawer-paper': {
                        boxShadow: 'unset',
                        width: 'calc(50vw - 15px)',
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '104.67px'
                        },
                        [theme.breakpoints.down('md')]: {
                            top: '70px',
                            width: '100%'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    window: PropTypes.object
};

export default memo(Sidebar);

import styled, { keyframes } from 'styled-components';
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';

// ==============================|| GRADIENT BACKGROUND ||============================== //

const rotate = keyframes`
    0% { transform: rotate(0deg); }
    50% { transform: rotate(15deg); }
    100% { transform: rotate(0deg); }
`;

const blur1 = keyframes`
    0% { transform: translateX(0em) translateY(0em) translateZ(0em); }
    33.33% { transform: translateX(0em) translateY(0em) translateZ(15em); }
    66.66% { transform: translateX(0em) translateY(0em) translateZ(-15em);}
    100% { transform: translateX(0em) translateY(0em) translateZ(0em); }
`;

const Background = styled.div`
    background: #ffffff;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const Bg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    perspective: 800px;
    z-index: 30;
`;

const UnderLayer = styled.div`
    display: block;
    background-size: cover;
    opacity: 0.8;
    width: 100vw;
    height: 5600px;
    background: linear-gradient(#1f1f1fff, #ffffffff);
`;

const BlurContainer = styled.div`
    animation-name: ${rotate};
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transform-origin: top left;
    transform-style: preserve-3d;
    perspective: 800px;
    width: 0;
    height: 0;
    position: absolute;
`;

const StaticContainer = styled.div`
    perspective: 800px;
    width: 0;
    height: 0;
    position: absolute;
`;

const BlurObject = styled.span`
    display: block;
    border-radius: 100%;
    background-size: cover;
    filter: blur(20vw);
    opacity: 0.8;
    @media screen and (min-width: 601px) and (max-width: 900) {
        filter: blur(15vw);
    }
    @media screen and (min-width: 901px) and (max-width: 1200px) {
        filter: blur(10vw);
    }
    @media screen and (min-width: 1201px) and (max-width: 1535px) {
        filter: blur(8vw);
    }
    @media screen and (min-width: 1536px) and (max-width: 1920px) {
        filter: blur(10vw);
    }
    @media screen and (min-width: 1921px) {
        filter: blur(15vw);
    }
`;

const blurObjectOne = {
    width: '170vw',
    height: '175vw',
    background: 'radial-gradient(circle at 50% 50%, #fcb336ff 60%, #073aff00 100%)',
    '@media (min-width: 601px) and (max-width: 1200px)': {
        width: '126vw',
        height: '122.55vw'
    },
    '@media (min-width: 1201px)': {
        width: '84vw',
        height: '81.7vw'
    }
};

const blurObjectTwo = {
    width: '106vw',
    height: '112vw',
    background: 'radial-gradient(circle at 50% 50%, #ffffffff 60%, #073aff00 100%)',
    '@media (min-width: 601px) and (max-width: 1200px)': {
        width: '79.5vw',
        height: '84vw'
    },
    '@media (min-width: 1201px)': {
        width: '53vw',
        height: '56vw'
    }
};

const blurObjectThree = {
    width: '170vw',
    height: '176vw',
    background: 'radial-gradient(circle at 50% 50%, #5b68dfff 60%, #073aff00 100%)',
    '@media (min-width: 601px) and (max-width: 1200px)': {
        width: '97.5vw',
        height: '94.5vw'
    },
    '@media (min-width: 1201px)': {
        width: '65vw',
        height: '63vw'
    }
};

const blurObjectFour = {
    width: '160vw',
    height: '160vw',
    background: 'radial-gradient(circle at 50% 50%, #5bafdfff 60%, #073aff00 100%)',
    '@media (min-width: 601px) and (max-width: 1200px)': {
        width: '68.1vw',
        height: '66.15vw'
    },
    '@media (min-width: 1201px)': {
        width: '45.4vw',
        height: '44.1vw'
    }
};

const blurObjectFive = {
    width: '120.8vw',
    height: '117.6vw',
    background: 'radial-gradient(circle at 50% 50%, #089494ff 60%, #073aff00 100%)',
    '@media (min-width: 601px) and (max-width: 1200px)': {
        width: '90.6vw',
        height: '88.2vw'
    },
    '@media (min-width: 1201px)': {
        width: '60.4vw',
        height: '58.8vw'
    }
};

const blurObjectSix = {
    width: '160vw',
    height: '160vw',
    background: 'radial-gradient(circle at 50% 50%, #73e0a9ff 60%, #073aff00 100%)',
    marginLeft: '-15vw',
    '@media (min-width: 601px) and (max-width: 1200px)': {
        width: '44.7vw',
        height: '46.95vw',
        marginLeft: 0
    },
    '@media (min-width: 1201px)': {
        width: '29.8vw',
        height: '31.3vw',
        marginLeft: 0
    }
};

const blurObjectSeven = {
    width: '200vw',
    height: '200vw',
    background: 'radial-gradient(circle at 50% 50%, #ff8718ff 60%, #073aff00 100%)',
    '@media (min-width: 601px) and (max-width: 1200px)': {
        width: '97.95vw',
        height: '70.5vw'
    },
    '@media (min-width: 1201px)': {
        width: '65.3vw',
        height: '49.7vw'
    }
};

const blurObjectEight = {
    width: '200vw',
    height: '200vw',
    background: 'radial-gradient(circle at 50% 50%, #ff8718ff 60%, #073aff00 100%)',
    '@media (min-width: 601px) and (max-width: 1200px)': {
        width: '97.95vw',
        height: '70.5vw'
    },
    '@media (min-width: 1201px)': {
        width: '65.3vw',
        height: '49.7vw'
    }
};

const blurObjectNine = {
    width: '200vw',
    height: '200vw',
    background: 'radial-gradient(circle at 50% 50%, #ff8718ff 60%, #073aff00 100%)',
    marginTop: '40vw',
    '@media (min-width: 601px) and (max-width: 1200px)': {
        width: '84.45vw',
        height: '70.5vw',
        marginTop: 0
    },
    '@media (min-width: 1201px)': {
        width: '56.3vw',
        height: '49.7vw',
        marginTop: 0
    }
};

const blurObjectTen = {
    width: '170vw',
    height: '170vw',
    background: 'radial-gradient(circle at 50% 50%, #809fb4ff 60%, #073aff00 100%)',
    marginTop: '-50vw',
    '@media (min-width: 601px) and (max-width: 1200px)': {
        width: '84.45vw',
        height: '70.5vw',
        marginTop: '0vw'
    },
    '@media (min-width: 1201px)': {
        width: '56.3vw',
        height: '49.7vw',
        marginTop: '0vw'
    }
};

const blurObjectEleven = {
    width: '170vw',
    height: '170vw',
    background: 'radial-gradient(circle at 50% 50%, #ef7217ff 60%, #073aff00 100%)',
    marginTop: '-50vw',
    '@media (min-width: 601px) and (max-width: 1200px)': {
        width: '84.45vw',
        height: '70.5vw',
        marginTop: '0vw'
    },
    '@media (min-width: 1201px)': {
        width: '56.3vw',
        height: '49.7vw',
        marginTop: '0vw'
    }
};

const blurObjectTwelve = {
    width: '170vw',
    height: '170vw',
    background: 'radial-gradient(circle at 50% 50%, #fdc031ff 60%, #073aff00 100%)',
    marginTop: '-50vw',
    '@media (min-width: 601px) and (max-width: 1200px)': {
        width: '84.45vw',
        height: '70.5vw',
        marginTop: '0vw'
    },
    '@media (min-width: 1201px)': {
        width: '56.3vw',
        height: '49.7vw',
        marginTop: '0vw'
    }
};

const blurObjectThirteen = {
    width: '170vw',
    height: '170vw',
    background: 'radial-gradient(circle at 50% 50%, #de3e2299 60%, #073aff00 100%)',
    marginTop: '-50vw',
    '@media (min-width: 601px) and (max-width: 1200px)': {
        width: '84.45vw',
        height: '70.5vw'
    },
    '@media (min-width: 1201px)': {
        width: '56.3vw',
        height: '49.7vw'
    }
};

const GradientBackground = () => {
    const theme = useTheme();

    return (
        <Background>
            <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 5 }}>
                <UnderLayer />
            </div>
            <Bg>
                <StaticContainer style={{ left: '50vw', top: '-352px' }}>
                    <BlurObject style={blurObjectOne} />
                </StaticContainer>
                <StaticContainer style={{ left: '-10vw', top: '1408px' }}>
                    <BlurObject style={blurObjectTwo} />
                </StaticContainer>
                <StaticContainer style={{ left: '0vw', top: '470px' }}>
                    <BlurObject style={blurObjectThree} />
                </StaticContainer>
                <StaticContainer style={{ left: '20vw', top: '1173px' }}>
                    <BlurObject style={blurObjectFour} />
                </StaticContainer>
                <StaticContainer style={{ left: '0vw', top: '1642px' }}>
                    <BlurObject style={blurObjectFive} />
                </StaticContainer>
                <StaticContainer style={{ left: '55vw', top: '2053px' }}>
                    <BlurObject style={blurObjectSix} />
                </StaticContainer>
                <StaticContainer style={{ left: '52vw', top: '2874px' }}>
                    <BlurObject style={blurObjectSeven} />
                </StaticContainer>
                <StaticContainer style={{ left: '0vw', top: '2991px' }}>
                    <BlurObject style={blurObjectEight} />
                </StaticContainer>
                <StaticContainer style={{ left: '40vw', top: '3636px' }}>
                    <BlurObject style={blurObjectNine} />
                </StaticContainer>
                <StaticContainer style={{ left: '-10vw', top: '5102px' }}>
                    <BlurObject style={blurObjectTen} />
                </StaticContainer>
                <StaticContainer style={{ left: '50vw', top: '5279px' }}>
                    <BlurObject style={blurObjectEleven} />
                </StaticContainer>
                <StaticContainer style={{ left: '-20vw', top: '5455px' }}>
                    <BlurObject style={blurObjectTwelve} />
                </StaticContainer>
                <StaticContainer style={{ left: '60vw', top: '5630px' }}>
                    <BlurObject style={blurObjectThirteen} />
                </StaticContainer>
            </Bg>
        </Background>
    );
};

export default GradientBackground;

import { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Container, Grid, Link, Menu, MenuItem, SvgIcon, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, Person } from '@mui/icons-material';
import { HashLink } from 'react-router-hash-link';

// project imports
import LogoSection from '../LogoSection';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import useAuth from 'hooks/useAuth';
import MenuBtn from '../MenuBtn';
import hotmobLogo from 'assets/images/header/logo.png';

// assets
import { IconMenu2 } from '@tabler/icons';
import MenuOption from './MenuOption';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { logout } = useAuth();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Grid container spacing={0} sx={{ width: '100% !important', marginLeft: '0 !important' }}>
            <Grid item xs={4}>
                <Link component={RouterLink} to="/home">
                    <Box component="img" alt="hotmob-logo" src={hotmobLogo} sx={{ width: '150px', height: 'auto' }} />
                </Link>
            </Grid>
            <Grid item xs={8}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
                    <MenuOption
                        name="Media Service"
                        haveDropDown
                        dropdownDetail={[
                            { name: 'Media Service', url: '/media-service' },
                            { name: 'Data-driven Programmatic', url: '/data-driven-programmatic' },
                            { name: 'Premium Placement', url: '/premium-placement' },
                            { name: 'Media Planning Management', url: '/media-planning-management' },
                            { name: 'China Market Solutions', url: '/china-market-solutions' },
                            { name: 'AI Content Creation', url: '/ai-content-creation' }
                        ]}
                        url="/media-service"
                    />
                    <MenuOption
                        name="Data Service"
                        haveDropDown
                        dropdownDetail={[
                            { name: 'Data Service', url: '/data-service' },
                            { name: 'Demo 1', url: '' },
                            { name: 'Demo 2', url: '' }
                        ]}
                        url="/data-service"
                    />
                    <MenuOption name="AD Gallery" url="https://showcase.hot-mob.com/" isExternal />
                    <MenuOption name="Blog" url="/blog" />
                    <HashLink smooth to="/#contact-us" style={{ alignSelf: 'center' }}>
                        <Button
                            variant="contained"
                            size="large"
                            sx={{
                                backgroundColor: '#E15C12',
                                color: '#FFFFFF',
                                fontWeight: 700,
                                '&:hover': {
                                    backgroundColor: '#E15C12',
                                    color: '#FFFFFF'
                                }
                            }}
                        >
                            Contact Us
                        </Button>
                    </HashLink>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Header;

import React, { useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Grid, Toolbar, Typography, useMediaQuery } from '@mui/material';

// project imports
import Header from './Header';
import Footer from './Footer';
import GradientBackground from './GradientBackground';
import useConfig from 'hooks/useConfig';
import { useDispatch, useSelector } from 'store';
import { format } from 'date-fns';

// assets
import { IconChevronRight } from '@tabler/icons';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1100,
            lg: 1280
        }
    }
});

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent
}));

// ==============================|| TESTING LAYOUT ||============================== //

const TestingLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();

    const [showBoxShadow, setShowBoxShadow] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            const scroll = window.pageYOffset;
            if (scroll > 10) {
                setShowBoxShadow(true);
            } else {
                setShowBoxShadow(false);
            }
        });
    }, []);

    const header = useMemo(
        () => (
            <Container sx={{ [theme.breakpoints.up(1280)]: { maxWidth: 'unset', bgcolor: '#3C3C3CCC', backdropFilter: 'blur(5px)' } }}>
                <Toolbar sx={{ padding: '0 24px !important', maxWidth: '1280px', margin: '0 auto' }}>
                    <Header />
                </Toolbar>
            </Container>
        ),
        []
    );

    return (
        <Box sx={{ display: 'flex', position: 'relative', overflowX: 'hidden' }}>
            <CssBaseline />
            {/* header */}
            <AppBar enableColorOnDark position="fixed" color="transparent" elevation={0}>
                {header}
            </AppBar>

            <GradientBackground />

            {/* main content */}
            <Main
                sx={{
                    background: 'transparent',
                    marginRight: 0,
                    padding: 0
                }}
            >
                {/* breadcrumb */}
                <Container sx={{ maxWidth: 'unset !important' }}>
                    <ThemeProvider theme={theme}>
                        <Outlet />
                        <Footer />
                    </ThemeProvider>
                </Container>
            </Main>
        </Box>
    );
};

export default TestingLayout;

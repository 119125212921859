import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import PropTypes from 'prop-types';

const MenuOption = ({ name, haveDropDown, dropdownDetail, url, isExternal }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (e) => {
        if (anchorEl !== e.currentTarget) {
            setAnchorEl(e.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isMenuOptionActive = () => {
        console.log(location.pathname);
        let color = '#FFFFFF99';
        if (haveDropDown) {
            dropdownDetail.forEach((opt, index) => {
                if (opt.url === location.pathname) {
                    color = '#FFFFFF';
                }
            });
        } else if (!haveDropDown && url === location.pathname) {
            color = '#FFFFFF';
        }

        return color;
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
                id={name.toLowerCase().replaceAll(' ', '-')}
                variant="text"
                aria-owns={open ? `${name.toLowerCase().replaceAll(' ', '-')}-menu` : null}
                aria-haspopup="true"
                sx={{
                    color: isMenuOptionActive,
                    fontSize: '16px',
                    marginTop: 0,
                    fontFamily: 'PlusJakartaSans',
                    fontWeight: 700,
                    lineHeight: '30px',
                    alignSelf: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#FFFFFF'
                    }
                }}
                onClick={() => {
                    if (isExternal) {
                        window.open(`${url}`, '_blank');
                    } else {
                        navigate(`${url}`);
                    }
                }}
                onMouseOver={handleClick}
            >
                {name}
            </Button>
            {haveDropDown && <ArrowDropDown sx={{ color: '#E15C12' }} />}
            {haveDropDown && (
                <Menu
                    id={`${name.toLowerCase().replaceAll(' ', '-')}-menu`}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{ onMouseLeave: handleClose }}
                    sx={{ '& .MuiPaper-root': { bgcolor: '#505051C9', backdropFilter: 'blur(5px)' } }}
                >
                    {dropdownDetail &&
                        dropdownDetail.map((option, index) => (
                            <MenuItem
                                key={index}
                                sx={{
                                    fontFamily: 'PlusJakartaSans',
                                    fontSize: '16px',
                                    color: '#FFFFFF',
                                    margin: index > 0 ? '8px 16px 8px 32px' : '8px 16px 8px 8px'
                                }}
                                onClick={() => navigate(`${option.url}`)}
                            >
                                {option.name}
                            </MenuItem>
                        ))}
                </Menu>
            )}
        </Box>
    );
};

MenuOption.propTypes = {
    name: PropTypes.string,
    haveDropDown: PropTypes.bool,
    dropdownDetail: PropTypes.array,
    url: PropTypes.string
};

export default MenuOption;

import { createSlice } from '@reduxjs/toolkit';

import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    error: null,
    postDetail: null
};

const slice = createSlice({
    name: 'postDetail',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setPostDetail(state, action) {
            state.postDetail = action.payload;
        },
        unsetPostDetail(state, action) {
            state.postDetail = null;
        }
    }
});

export default slice.reducer;

export function getBlogPost(data, callback) {
    return async () => {
        try {
            const response = await axios.get(`/api/post/detail/${data}`, {});
            dispatch(slice.actions.setPostDetail(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function clearPostInStore(data, callback) {
    return () => {
        dispatch(slice.actions.unsetPostDetail());
        if (callback) {
            callback();
        }
    };
}

import React, { useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, Typography, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { useDispatch, useSelector } from 'store';
import { format } from 'date-fns';

// assets
import { IconChevronRight } from '@tabler/icons';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1100,
            lg: 1280
        }
    }
});

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent
}));

// ==============================|| TESTING LAYOUT ||============================== //

const TestingLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();

    const [showBoxShadow, setShowBoxShadow] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            const scroll = window.pageYOffset;
            if (scroll > 10) {
                setShowBoxShadow(true);
            } else {
                setShowBoxShadow(false);
            }
        });
    }, []);

    const header = useMemo(
        () => (
            <Container
                sx={{
                    [theme.breakpoints.up(1440)]: { maxWidth: '1440px' },
                    [theme.breakpoints.up(1200)]: { maxWidth: 'unset' }
                }}
            >
                <Toolbar
                    disableGutters
                    sx={{
                        minHeight: '90px',
                        padding: '30px 0 20px 0',
                        justifyContent: 'space-between',
                        [theme.breakpoints.down(1099.95)]: {
                            minHeight: '70px',
                            padding: '20px 0 10px 0'
                        }
                    }}
                >
                    <Header />
                </Toolbar>
            </Container>
        ),
        []
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: drawerOpen ? theme.transitions.create('width') : 'none',
                    boxShadow: showBoxShadow ? '0 3px 6px #E6E6E6' : 'unset'
                }}
            >
                {header}
            </AppBar>

            {/* drawer */}
            <Sidebar />

            {/* main content */}
            <Main
                open={drawerOpen}
                sx={{
                    background: '#FFFFFF',
                    marginRight: 0,
                    padding: 0,
                    [theme.breakpoints.down(600)]: {
                        marginTop: '70px !important'
                    }
                }}
            >
                {/* breadcrumb */}
                <Container
                    sx={{
                        [theme.breakpoints.up(1200)]: { maxWidth: 'unset' },
                        [theme.breakpoints.up(600)]: { paddingLeft: 0, paddingRight: 0 },
                        [theme.breakpoints.down(600)]: { padding: 0 }
                    }}
                >
                    <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                    <ThemeProvider theme={theme}>
                        <Outlet />
                    </ThemeProvider>
                </Container>
                <footer style={{ padding: '20px 0', zIndex: 20 }}>
                    <Container sx={{ display: 'flex', [theme.breakpoints.up(1280)]: { maxWidth: '1280px' } }}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: 'inherit',
                                fontSize: '1.25rem',
                                marginTop: 0,
                                fontFamily: 'Raleway, san-self',
                                fontWeight: 400,
                                lineHeight: 1.6,
                                letterSpacing: '0.0075em',
                                [theme.breakpoints.down(960)]: {
                                    fontSize: '0.75rem'
                                }
                            }}
                        >
                            Copyright © Hotmob Limited {format(new Date(), 'yyyy')}
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                color: '#E15C12',
                                fontSize: '1.25rem',
                                marginTop: 0,
                                marginLeft: '0.5rem',
                                fontFamily: 'Raleway, san-self',
                                fontWeight: 400,
                                lineHeight: 1.6,
                                letterSpacing: '0.0075em',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                [theme.breakpoints.down(960)]: {
                                    fontSize: '0.75rem'
                                }
                            }}
                            onClick={() => {
                                window.open('https://www.hot-mob.com/privacy-policy/');
                            }}
                        >
                            Privacy Policy
                        </Typography>
                    </Container>
                </footer>
            </Main>
        </Box>
    );
};

export default TestingLayout;

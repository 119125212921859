import PropTypes from 'prop-types';

import { createStyles, makeStyles, Theme } from '@mui/styles';

import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
    createStyles({
        menuBtn: {
            position: 'relative',
            zIndex: 1201
        },
        menuSpan: {
            transformOrigin: 'center',
            transition: 'all 0.3s'
        },
        menuSpan1Open: {
            transform: 'rotateZ(45deg)',
            width: '120%',
            right: '-10%',
            top: '50%'
        },
        menuSpan2Open: {
            width: '0%',
            opacity: 0
        },
        menuSpan3Open: {
            transform: 'rotateZ(-45deg)',
            width: '120%',
            right: '-10%',
            top: '50%'
        }
    })
);

const MenuBtn = ({ btnWidth, btnHeight }) => {
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const classes = useStyles();
    const theme = useTheme();
    const boxHeight = Math.trunc(btnHeight * 0.1);
    const boxMargin = (100 - boxHeight * 3) / 4;
    const boxStyle = {
        position: 'absolute',
        bgcolor: '#d3531a',
        borderRadius: 30,
        height: boxHeight,
        right: 0
    };

    return (
        <Box
            sx={{
                width: btnWidth,
                height: btnHeight,
                [theme.breakpoints.up('md')]: {
                    marginRight: '-15px'
                }
            }}
            className={clsx(classes.menuBtn)}
            onClick={() => {
                dispatch(openDrawer(!drawerOpen));
            }}
        >
            <Box
                className={clsx(classes.menuSpan, drawerOpen && classes.menuSpan1Open)}
                sx={{
                    ...boxStyle,
                    width: '33%',
                    top: `${boxMargin}%`
                }}
            />
            <Box
                className={clsx(classes.menuSpan, drawerOpen && classes.menuSpan2Open)}
                sx={{
                    ...boxStyle,
                    width: '66%',
                    top: `${boxMargin * 2 + boxHeight}%`
                }}
            />
            <Box
                className={clsx(classes.menuSpan, drawerOpen && classes.menuSpan3Open)}
                sx={{
                    ...boxStyle,
                    width: '100%',
                    top: `${boxMargin * 3 + boxHeight * 2}%`
                }}
            />
        </Box>
    );
};

MenuBtn.propTypes = {
    btnWidth: PropTypes.number,
    btnHeight: PropTypes.number
};

export default MenuBtn;

import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const HomePage = Loadable(lazy(() => import('views/home-page')));
const MediaServicePage = Loadable(lazy(() => import('views/media-service-page')));
const PremiumPlacementPage = Loadable(lazy(() => import('views/premium-placement-page')));
const MediaPlanningManagementPage = Loadable(lazy(() => import('views/media-planning-management-page')));
const ChinaMarketSolutionsPage = Loadable(lazy(() => import('views/china-market-solutions-page')));
const DataDrivenProgrammaticPage = Loadable(lazy(() => import('views/data-driven-programmatic-page')));
const AiContentCreationPage = Loadable(lazy(() => import('views/ai-content-creation-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '/home',
            element: <HomePage />
        },
        {
            path: '/media-service',
            element: <MediaServicePage />
        },
        {
            path: '/premium-placement',
            element: <PremiumPlacementPage />
        },
        {
            path: '/media-planning-management',
            element: <MediaPlanningManagementPage />
        },
        {
            path: '/china-market-solutions',
            element: <ChinaMarketSolutionsPage />
        },
        {
            path: '/data-driven-programmatic',
            element: <DataDrivenProgrammaticPage />
        },
        {
            path: '/ai-content-creation',
            element: <AiContentCreationPage />
        }
    ]
};

export default MainRoutes;
